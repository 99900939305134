import Image from 'next/image'
import React, { useCallback, useEffect, useState } from 'react'
import { css } from 'styled-system/css'
import { styled } from 'styled-system/jsx'
import styles from './talking.module.css'
import { motion } from 'framer-motion'
import useTasks from '@/hooks/main/category/useTasks'
import { Dialogue } from '../types'
import { Message } from 'src/types/mail'
import { useMail } from '@/hooks/useMail'
import { useSearchParamActions } from '@/hooks/useSearchParamActions'
import { usePathname } from 'next/navigation'
import { useGetAchievementQuery } from '@/redux/features/api'
import { skipToken } from '@reduxjs/toolkit/query'
import { useAppDispatch } from '@/redux/hooks'
import { setTemporaryState } from '@/redux/features/temporaryStateSlice'

const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } }
const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible,
}

enum BuiltIn {
    cheerUpSadPanda = 'cheer-up-sad-panda',
}

const TalkingPanda = ({
    onNext,
    text: givenText = "Type any text here and the bubble will grow to fit the text no matter how many lines.  Isn't that nifty?",
    dialogue,
    isActive,
    setIsActive,
}: {
    text: string
    onNext: (key: number | string) => void
    isActive: boolean
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
    dialogue: Dialogue
}) => {
    const pathname = usePathname()
    const [lastToggled, setLastToggled] = useState(Date.now())
    const { shallowReplace } = useSearchParamActions()
    const { overdueTasks } = useTasks()
    const [builtInDialogue, setBuiltInDialogue] = useState<null | BuiltIn>(null)
    const importantOverdueTasks = overdueTasks.filter((x) => x.priority > 1)
    const text = builtInDialogue ? `Complete ${importantOverdueTasks.map((x) => x.name).join(', ')}` : givenText
    const dispatch = useAppDispatch()
    const { messages, setMessageAsRead } = useMail()

    const next = dialogue.next
    const { data: achievement, isLoading: achievementIsLoading } = useGetAchievementQuery(dialogue.nextOnAchievement || skipToken)

    const options = builtInDialogue ? [] : [
        ...(dialogue.responses || []),
        ...(importantOverdueTasks.length > 0 && !builtInDialogue ? [{ reply: 'How can I cheer you up?', next: BuiltIn.cheerUpSadPanda }] : []),
    ]

    const handleNext = useCallback(
        (key: number | string | undefined) => {
            if (!key || key === 'end') {
                setIsActive(false)
            }
            if (builtInDialogue) {
                setBuiltInDialogue(null)
                return
            }
            if (key === BuiltIn.cheerUpSadPanda) {
                setBuiltInDialogue(BuiltIn.cheerUpSadPanda)
                return
            }
            shallowReplace()
            if (dialogue.tutorialArrow) {
                dispatch(setTemporaryState({ tutorialArrow: undefined }))
            }
            onNext(key || '')
        },
        [onNext, setIsActive, shallowReplace, dialogue.tutorialArrow, builtInDialogue, setBuiltInDialogue]
    )

    useEffect(() => {
        dispatch(setTemporaryState({ tutorialArrow: dialogue.tutorialArrow }))
    }, [dialogue.text])

    const handleContinue = useCallback(() => {
        if (!isActive) {
            setIsActive(true)
        } else {
            handleNext(next)
        }
        dialogue.onClick?.(setIsActive)
        setLastToggled(Date.now())
    }, [isActive, dialogue, setIsActive, handleNext, next])

    useEffect(() => {
        if (dialogue.nextOnPage?.(pathname)) {
            handleContinue()
        }
    }, [dialogue, handleContinue, pathname])

    useEffect(() => {
        if (!dialogue.nextOnAchievement || !achievement) return
        if (achievement.id === dialogue.nextOnAchievement && achievement.count > 0) {
            handleContinue()
        }
    }, [dialogue.nextOnAchievement, handleContinue, achievement])

    if (messages.length > 0) {
        return <MailPanda isActive={isActive} message={messages[0]} setMessageAsRead={setMessageAsRead} setIsActive={setIsActive} />
    }

    const onClick = () => {
        if (dialogue.requiresAction) return
        handleContinue()
    }

    return (
        <PandaWrapper>
            {isActive && (
                <motion.div
                    onClick={onClick}
                    transition={{ duration: 0.1 }}
                    layout={'size'}
                    className={`${styles.bubble} ${styles.bubbleBottomLeft}`}
                >
                    {text}
                    {options.length === 0 && (
                        <button className={styles.nextButton}>
                            {dialogue.requiresAction ? 'Action required' : next === 'end' ? 'Close' : 'Next'}
                        </button>
                    )}
                    <div className={styles.bubbleArrowWrapper}>
                        <div className={styles.bubbleArrow}></div>
                    </div>
                </motion.div>
            )}
            {isActive && (
                <OptionsContainer
                    initial="hidden"
                    className={styles.header}
                    animate="visible"
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                    variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
                >
                    {options.map(({ reply: label, next, onClick }) => {
                        return (
                            <motion.button
                                onClick={() => {
                                    onClick?.(setIsActive)
                                    handleNext(next)
                                }}
                                key={label}
                                className={styles.bubble}
                                variants={itemVariants}
                            >
                                {label}
                            </motion.button>
                        )
                    })}
                </OptionsContainer>
            )}
            {/* TODO: QuestionMarkCircledIcon on inactive instead of panda in plain mode  */}
            <ImageWrapper
                initial="visible"
                animate={isActive ? 'active' : 'inactive'}
                onClick={onClick}
                variants={{ active: { scale: 1 }, inactive: { scale: 0.2, bottom: 0 } }}
            >
                {importantOverdueTasks.length > 0 ? (
                    <Image src={'/pandas/sad_panda.png'} alt="tutorial sad panda" className={css({ objectFit: 'contain' })} fill />
                ) : (
                    <Image src={'/pandas/panda_standing.png'} alt="tutorial panda" className={css({ objectFit: 'contain' })} fill />
                )}
            </ImageWrapper>
        </PandaWrapper>
    )
}

export default TalkingPanda

const MailPanda = ({
    isActive,
    message,
    setMessageAsRead,
    setIsActive,
}: {
    isActive: boolean
    message: Message
    setMessageAsRead: (id: string) => void
    setIsActive: (val: boolean) => void
}) => {
    return (
        <PandaWrapper>
            {isActive && (
                <motion.div
                    onClick={() => setMessageAsRead(message.id)}
                    transition={{ duration: 0.5 }}
                    layout={'size'}
                    className={`${styles.bubble} ${styles.bubbleBottomLeft}`}
                >
                    {'"' + message.message + '"' + '\n' + message.from}
                    <div className={styles.bubbleArrowWrapper}>
                        <div className={styles.bubbleArrow}></div>
                    </div>
                </motion.div>
            )}
            {isActive && (
                <OptionsContainer
                    initial="hidden"
                    className={styles.header}
                    animate="visible"
                    exit={{ opacity: 0, transition: { duration: 1 } }}
                    variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
                >
                    {[
                        {
                            reply: 'Next',
                            onClick: () => setMessageAsRead(message.id),
                        },
                        {
                            reply: 'Go away',
                            onClick: () => setIsActive(false),
                        },
                    ].map(({ reply: label, onClick }) => {
                        return (
                            <motion.button onClick={onClick} key={label} className={styles.bubble} variants={itemVariants}>
                                {label}
                            </motion.button>
                        )
                    })}
                </OptionsContainer>
            )}
            <ImageWrapper
                initial="visible"
                animate={isActive ? 'active' : 'inactive'}
                onClick={() => setIsActive(!isActive)}
                variants={{ active: { scale: 1 }, inactive: { scale: 0.3, bottom: 0 } }}
            >
                <Image
                    src={'/pandas/mail_panda.png'} // TODO: need a mail panda
                    alt="mailman panda"
                    className={css({ objectFit: 'contain' })}
                    fill
                />
            </ImageWrapper>
        </PandaWrapper>
    )
}

const OptionsContainer = styled(motion.div, {
    base: {
        display: 'flex',
        flexDirection: 'column',
        gridArea: 'options',
        gap: 20,
        alignItems: 'center',
        marginTop: 30,
    },
})

const PandaWrapper = styled('div', {
    base: {
        position: 'absolute',
        bottom: 15,
        right: 15,
        display: 'grid',
        gridTemplateAreas: '"speech ."\n    "options panda"',
        zIndex: 100,
        pointerEvents: 'none',
        '& *': {
            pointerEvents: 'auto',
        },
    },
})

const ImageWrapper = styled(motion.div, {
    base: {
        boxSizing: 'border-box',
        content: 'image-wrapper',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        overflow: 'hidden',
        userSelect: 'none',
        height: 300,
        width: 300,
        position: 'relative',
        gridArea: 'panda',
        marginLeft: -50,
        transformOrigin: 'bottom right',
    },
})
