import React from 'react'
import RadioGroup, { RadioGroupProps } from './RadioGroup'

const YesNoRadio = ({ value, handleChange }: Omit<RadioGroupProps, 'radioItems'>) => {
    return (
        <RadioGroup
            radioItems={[
                {
                    id: 'yes',
                    value: 'yes',
                    label: 'Yes',
                },
                {
                    id: 'no',
                    value: 'no',
                    label: 'No',
                }
            ]}
            handleChange={handleChange}
            value={value}
        />
    )
}

export default YesNoRadio