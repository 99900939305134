'use client'
import getNextTheme, { getStoredTheme } from '@/utils/getNextTheme'
import { getTheme, injectTheme, ThemeName } from 'styled-system/themes'
import { useEffect, useState } from 'react'
import { themeNames } from '../../panda.config'
import { DEFAULT_THEME } from '@/constants/style'
import { setCookie } from 'cookies-next'


const useTheme = () => {
    const [theme, setCurrentTheme] = useState(getStoredTheme())

    const setTheme = async (theme: ThemeName) => {
        const pandaTheme = await getTheme(theme)
        setCookie('theme', theme)
        injectTheme(document.documentElement, pandaTheme)
        setCurrentTheme(theme)
    }

    const setNextTheme = async () => {
        const nextTheme = getNextTheme() as ThemeName
        setTheme(nextTheme)
    }

    useEffect(() => {
        if (!theme || !themeNames.includes(theme as any)) {
            setTheme(DEFAULT_THEME)
        }
    }, [])

    return {
        theme: theme as ThemeName | undefined,
        setTheme,
        setNextTheme,
    }
}

export default useTheme
