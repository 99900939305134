import { stitchesToPandaTokens } from "@planda/design-system"

// cannot put '{colors.$hiContrast}' in colors, have to put in semanticTokens.colors
export const avocadoVariables = {
    tan2v: '#d2b48c',
    gray1v: '#d1d1d1',
    brown1v: '#917d63',
    brown2v: '#7a6850',
    lightbrownv: '#ab977d',

    // ltan3: '#ede7da',
    // ltan: '#f0e2c5',
    // ltan2: '#faeccf',
    lbrownv: '#c9bea7',
    tanv: '#d6c9b6',
    // text: '$hiContrast',
    // lightgreen: '#edf5e6',
    // green1: '#bdccaf',
    // lightgreen2: '#dfebd5',
    lightgreen3v: '#d2e0c5',
    // darkgreen: '#547556',
}

export const avocadoTheme = {
    // outline: '$darkgreen',
    tasktxt: '{colors.$gray12}',
    taskbg: '{colors.$primary9}',
    // tasktxt: '$gray1v', // maybe could replace tasktxt+eventtxt with overlay
    eventbg: '{colors.$brown1v}',
    eventtxt: '{colors.$loContrast}',

    listbg: 'linear-gradient(to top left, white, {colors.$tan2v})',

    timegridbg: '{colors.$primary2}',
    timegridCellHover: '{colors.$primary4}',

    eventcardoutline: '{colors.$brown2v}',
    eventcardbg: '{colors.$lightbrownv}',
    eventcardtxt: '{colors.$hiContrast}',

    categorybg1: '#ddead1',
    categorytxt1: '{colors.$hiContrast}',

    categorybg2: '#c7ddb5',
    categorytxt2: '{colors.$hiContrast}',

    categorybg3: '#b3cf99',
    categorytxt3: '{colors.$hiContrast}',

    categorybg4: '#a3c585',
    categorytxt4: '{colors.$hiContrast}',

    categorybg5: '#95bb72',
    categorytxt5: '{colors.$hiContrast}',

    categorybg6: '#87ab69',
    categorytxt6: '{colors.$hiContrast}',

    categorybg7: '#75975e',
    categorytxt7: '{colors.$hiContrast}',

    categorybg8: '#658354',
    categorytxt8: '{colors.$hiContrast}',

    daygridBg: '{colors.$secondary4}', // '#EADDCA', // dark , !!
    daygridToday: '{colors.$secondary6}', // '$lbrownv', // dark

    calviewbg: '{colors.$lightgreen3v}',
    calviewborder: '{colors.$primary9}',
    calviewcolor: '{colors.$primary11}',

    // calviewbg: '$primary7',
    // calviewborder: '$primary10',
    // calviewcolor: '$primary10',

    // dashboardbg: '$lightgreen3',
    // notebook: '$lightgreen',
    // stickynote: '$lightbrown',
}

export const avocado = {
    tokens: {
        colors: stitchesToPandaTokens(avocadoVariables),
    },
    semanticTokens: {
        colors: stitchesToPandaTokens(avocadoTheme),
    }
}