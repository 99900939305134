import { motion } from 'framer-motion'
import { styled } from 'styled-system/jsx'
import { Flex, Spinner } from '@planda/design-system'
import { Cross1Icon } from '@radix-ui/react-icons'
import Link from 'next/link'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useGetShareLinkSettingsQuery } from '@/redux/features/api'
import { endViewMode } from '@/redux/features/view-mode/viewModeSlice'
import { forwardRef } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'

const DemoInfoPanel = forwardRef<any, any>(({}, constraintsRef) => {
    const viewMode = useAppSelector((state) => state.viewMode)
    const { data: shareSettings } = useGetShareLinkSettingsQuery(viewMode.shareLinkUrlInfo?.sharingId ?? skipToken)
    const dispatch = useAppDispatch()

    const endView = () => dispatch(endViewMode())

    if (!viewMode.mode) return <></>

    if (viewMode.mode === 'offline') {
        return (
            <DemoContainer
                drag
                dragElastic={0.01}
                // @ts-ignore
                dragConstraints={constraintsRef ?? undefined}
            >
                <Header>Offline mode: limited access</Header>
                <ul>
                    <li>View limited information about tasks+events</li>
                    <li>No editing existing tasks+events</li>
                </ul>
            </DemoContainer>
        )
    }

    return (
        <DemoContainer
            drag
            dragElastic={0.01}
            // @ts-ignore
            dragConstraints={constraintsRef ?? undefined}
        >
            {viewMode.mode === 'share' && !shareSettings ? (
                <Spinner />
            ) : shareSettings ? (
                <Header>{shareSettings.ownerName}&apos;s Planda</Header>
            ) : (
                <Header>Demo mode: View only</Header>
            )}
            <Link href={'/about'}>
                <Flex onClick={endView} gap={'1'} align={'center'}>
                    End {viewMode.mode === 'demo' ? 'demo' : 'visit'} <Cross1Icon />
                </Flex>
            </Link>
        </DemoContainer>
    )
})

DemoInfoPanel.displayName = 'DemoInfoPanel'

export default DemoInfoPanel

const DemoContainer = styled(motion.div, {
    base: {
        position: 'fixed',
        zIndex: 99999,
        width: 200,
        backgroundColor: '$secondary2',
        bottom: '5vh',
        left: '5vh',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 20,
        border: '3px ridge {colors.$gray10}',
        boxShadow: '$strong',
        '&:hover': {
            cursor: 'grab',
        },
    },
})

const Header = styled('h2', {
    base: {
        textAlign: 'center',
    },
})
