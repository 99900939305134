import { useCallback, useEffect, useState } from 'react'
import { Dialogues, Key } from '../types'

const usePandaDialogue = (dialogues: Dialogues, onEnd?: ()=>void) => {
    const [dialogKey, setDialogKey] = useState('0')
    const dialogue = dialogues[dialogKey]
    const handleNext = useCallback((val: Key) => {
        if (!val || val === 'end') {
            onEnd?.()
            return
        }
        val = val.toString()

        setDialogKey(val)
    }, [onEnd, setDialogKey])

    const [text, setText] = useState('') // handles async text
    useEffect(() => {
        if (!dialogue) console.error(`No dialogue found for key: ${dialogKey}`)
        if (typeof dialogue.text === 'string') {
            return setText(dialogue.text)
        }
        if (typeof dialogue.text === 'function') {
            const promise = dialogue.text()
            if (promise instanceof Promise) {
                promise.then((res) => {
                    setText(res)
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogKey, dialogue.text, setText])

    return {
        dialogue: { ...dialogue, text },
        handleNext,
    }
}

export default usePandaDialogue
