'use client'
import { Box, Flex, Separator, Tooltip } from '@planda/design-system'
import { css } from 'styled-system/css'
import Link from 'next/link'
import { BsCalendar4Week } from 'react-icons/bs'
import { useGlobalPanel } from './components/usePanel'
import { GhostIconButton } from '@/components/common'
import { useGetUserQuery } from '@/redux/features/api'
import { usePathname } from 'next/navigation'
import { useAppSelector } from '@/redux/hooks'
import {
    TOOLBAR_OPTION_LINKS_DICT,
    TOOLBAR_OPTION_PANEL_DICT,
    TOOLBAR_OPTION_POPOVER_DICT,
    ToolbarLinkId,
    ToolbarPanelId,
    ToolbarPopoverId,
} from './components/config'
import { memo } from 'react'
import dynamic from 'next/dynamic'
import ToolbarArrow from '../TutorialArrow'
import StoreBadge from './components/StoreBadge'
import FeatureAccess from '@/components/common/FeatureAccess'

const flexDirection = 'column'
const oppositeOrientation = 'horizontal'
const Divider = () => <Separator orientation={oppositeOrientation} size="1" className={css({ my: '$3' })} />

const ShowOptions = dynamic(() => import('./components/ShowOptions'))

const arrowClass = css.raw({ top: 11, scale: '55%', left: -8 })

export const Toolbar = memo(() => {
    const basePath = usePathname()
    const { data: user } = useGetUserQuery()
    const isAlpha = useAppSelector((state) => state.featureAccess.plugins.alpha)
    const { mode = 'standard' } = user || {}

    return (
        <Box
            className={css({
                zIndex: 5,
                position: 'sticky',
                top: '0',
                left: '0',
                backgroundColor: '$loContrast',
                height: '100%',
                px: 7.5,
                py: '$2',
                borderRight: '1px solid {colors.$canvas}',
            })}
        >
            <Flex
                justify={'between'}
                direction={flexDirection}
                className={css({
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                })}
            >
                <Flex direction={flexDirection} align={'center'}>
                    <Box>
                        <ShowOptions />
                    </Box>
                    <ToolbarPanelOption id="timegrid-panel" />
                    <ToolbarPanelOption id="daygrid-panel" />
                </Flex>
                <Flex direction={flexDirection} className={css({ alignItems: 'center', gap: 6 })}>
                    <ToolbarLinkOption id="home" />
                    <Divider />
                    <ToolbarLinkOption id="month" />
                    <ToolbarLinkOption id="weekTime" />
                    <ToolbarLinkOption id="list" />
                    <ToolbarLinkOption id="weekList" />
                    <Divider />
                    <ToolbarLinkOption id="habits" />
                    <ToolbarLinkOption id="goals" />
                    <StoreBadge>
                        <ToolbarLinkOption id="store" />
                    </StoreBadge>
                </Flex>
                <Flex align={'center'} direction={flexDirection} className={css({ alignItems: 'center', gap: 6 })}>
                    <ToolbarPanelOption id="tutorials" />
                    <ToolbarPopupOption id="profile" />
                    <ToolbarPopupOption id="settings" />
                </Flex>
            </Flex>
        </Box>
    )
})

Toolbar.displayName = 'Toolbar'

export default Toolbar

const ToolbarPopupOption = memo(({ id }: { id: ToolbarPopoverId }) => {
    const { Popover, title, icon } = TOOLBAR_OPTION_POPOVER_DICT[id]
    return (
        <Popover side={'right'}>
            <GhostIconButton>
                {icon}
                <ToolbarArrow cssRaw={arrowClass} tutorialArrow={id} />
            </GhostIconButton>
        </Popover>
    )
})

ToolbarPopupOption.displayName = 'ToolbarPopupOption'

const ToolbarLinkOption = memo(({ id }: { id: ToolbarLinkId }) => {
    const basePath = usePathname()
    const { href, title, icon, openLinkInNewTab, featureAccessKey } = TOOLBAR_OPTION_LINKS_DICT[id]
    const isDisabled = useAppSelector((state) => featureAccessKey && !state.featureAccess.featureAccessMap[featureAccessKey])

    if (isDisabled) return null
    return (
        <Box>
            <Tooltip content={title} side="right" align="center">
                <Link href={href} target={openLinkInNewTab ? '_blank' : undefined}>
                    <GhostIconButton inverted={basePath === href || basePath?.startsWith(href + '/')}>
                        {icon}
                        <ToolbarArrow cssRaw={arrowClass} tutorialArrow={id} />
                    </GhostIconButton>
                </Link>
            </Tooltip>
        </Box>
    )
})

ToolbarLinkOption.displayName = 'ToolbarLinkOption'

const ToolbarPanelOption = memo(({ id }: { id: ToolbarPanelId }) => {
    const { togglePanel } = useGlobalPanel()
    const { panelKey, title, icon, additionalTutorialArrowKeys, featureAccessKey } = TOOLBAR_OPTION_PANEL_DICT[id]
    const content = (
        <Box>
            <GhostIconButton
                onClick={() => {
                    togglePanel(panelKey)
                }}
            >
                {icon}
                {additionalTutorialArrowKeys?.map((x) => <ToolbarArrow key={x} cssRaw={arrowClass} tutorialArrow={x} />)}
                <ToolbarArrow cssRaw={arrowClass} tutorialArrow={id} />{' '}
            </GhostIconButton>
        </Box>
    )
    return featureAccessKey ? <FeatureAccess feature={featureAccessKey}>{content}</FeatureAccess> : content
})

ToolbarPanelOption.displayName = 'ToolbarPanelOption'
