import { defineGlobalStyles } from '@pandacss/dev'

export const globalCss = defineGlobalStyles({
    ':root': {
        margin: 0,
        fontFamily: 'system',
        height: '100%',
        color: '$primary12',
        boxSizing: 'border-box',
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box',
        scrollBehavior: 'smooth',
        scrollbarColor: 'auto transparent',
        background: '$primary1',
        fontSize: '1em',
    },
    body: {
        minHeight: '100vh',
        minWidth: 'max(100vw, 100lvw)',
        width: '100%',
        fontSize: '1em',
        margin: 0,
        padding: 0,
        // display: 'flex',
        // alignItems: 'stretch',
    },
    'h1, h2, h3, h4, h5, h6': {
        color: '$text',
        margin: 0,
        display: 'block',
        fontWeight: 'bold',
    },
    'h3, h4, h5, h6': {
        color: '$primary11',
    },
    main: {
        // TODO: delete this when done testing
        backgroundColor: '$primary1',
        width: '100vw',
    },
    sub: {
        verticalAlign: 'bottom',
        position: 'relative',
        bottom: '-0.5em',
        color: '$unimportant',
        fontSize: '0.65em',
    },
    textarea: {
        fontFamily: 'system',
        padding: 5,
        letterSpacing: 0.8,
        boxSizing: 'border-box',
        background: '$overlayB11',
        color: '$text',
        '&:focus': {
            // border: '$focus',
            outline: 'none !important',
            // border: '1px solid red',. .
            boxShadow: '$focus',
        },
    },
    a: {
        textDecoration: 'none',
        // color: '$indigo11',
        // '&:visited': {
        //   color: '$violet11',
        // }
    },
    '.link': {
        '&:link': {
            color: '$blue9',
        },
        '&:visited': {
            color: '$violet9',
        },
        '& a': {
            '&:link': {
                color: '$blue9',
            },
            '&:visited': {
                color: '$violet9',
            },
        },
    },
    embed: {
        backgroundColor: 'white',
    },
    input: {
        color: '$text',
        background: 'transparent',
    },
    li: {
        marginInline: '40px 0px',
    },
    '.highlight': {
        backgroundColor: '$primary3',
        padding: '1px 5px',
        borderRadius: '3px',
    }
})
