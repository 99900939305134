'use client'
// DUCKS pattern

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ViewModeState {
    mode?: 'share' | 'demo' | 'offline' // cannot enter offline mode if sharing or demo
    shareLinkUrlInfo?: {
        sharingId: string
        sharingBy: string
    } | null
}

const initialState = (): ViewModeState => {
    const isIframe = typeof window !== 'undefined' && window.self !== window.top
    if (isIframe)
        return {
            mode: 'demo',
        }

    if (typeof window !== 'undefined') {
        const demo = localStorage.getItem('demo')
        if (demo === 'true') {
            return {
                mode: 'demo',
            }
        }

        let sharingId = localStorage.getItem('sharingId')
        let sharingBy = localStorage.getItem('sharingBy')
        if (sharingBy && sharingId) {
            return {
                mode: 'share',
                shareLinkUrlInfo: {
                    sharingId: sharingId,
                    sharingBy: sharingBy,
                },
            }
        }

        const searchParams = new URLSearchParams(window.location.search)
        sharingId = searchParams.get('sharing')
        sharingBy = searchParams.get('sharingby')
        if (sharingId && sharingBy) {
            localStorage.setItem('sharingId', sharingId)
            localStorage.setItem('sharingBy', sharingBy)
            return {
                shareLinkUrlInfo: {
                    sharingId,
                    sharingBy,
                },
            }
        }
    } else {
        // throw new Error(
        //     "window is undefined in viewModeSlice.ts initial state"
        // );
    }

    return {}
}

const viewModeSlice = createSlice({
    name: 'view-mode',
    initialState,
    reducers: {
        setViewMode: (state, action: PayloadAction<ViewModeState>) => {
            'use client'
            const newState = action.payload
            if (typeof window === 'undefined') {
                return newState
            }
            if (state.mode !== newState.mode) {
                if (newState.mode === 'demo') {
                    localStorage.setItem('demo', 'true')
                } else if (state.mode === 'demo') {
                    localStorage.removeItem('demo')
                }
            }
            if (newState.shareLinkUrlInfo) {
                newState.mode = 'share'
                localStorage.setItem('sharingId', newState.shareLinkUrlInfo.sharingId)
                localStorage.setItem('sharingBy', newState.shareLinkUrlInfo.sharingBy)
            } else if (state.mode === 'share') {
                localStorage.removeItem('sharingId')
                localStorage.removeItem('sharingBy')
            }
            return newState
        },
        setOfflineMode: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.mode = 'offline'
            } else {
                state.mode = undefined
            }
        },
        endViewMode: () => {
            localStorage.removeItem('demo')
            localStorage.removeItem('sharingId')
            localStorage.removeItem('sharingBy')
            // TODO: do I need to redirect?
            return {}
        },
        load: initialState,
    },
})

export const { setViewMode, endViewMode, load: loadViewMode, setOfflineMode } = viewModeSlice.actions
export default viewModeSlice.reducer
