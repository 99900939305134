import { Panel, WhichPanel, setPanel, togglePanel } from '@/redux/features/temporaryStateSlice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

// export const PanelContext = createContext<PanelProps>({ panel: '', setPanel: () => { } })

// export const ColorModeProvider = ({ children }: { children: ReactNode }) => {
//     const termProps = useTerm()

//     return <TermContext.Provider value={termProps} >
//         <div></div>
//     </TermContext.Provider>
// }

export function useGlobalPanel() {
    const panel = useAppSelector(state => state.temporaryState.panel)
    const dispatch = useAppDispatch()

    return {
        panel,
        setPanel: (args: WhichPanel) => dispatch(setPanel(args)),
        togglePanel: (args: Panel) => dispatch(togglePanel(args)),
    }
}

// export function usePanel() {
//     const [item, setItem] = useState<string | null>(null)

//     const props: PanelProps = {
//         panel: item,
//         setPanel: setItem
//     }
//     return props
// }