import { Dialogues } from '../types'
import { usePathname } from 'next/navigation'
import { useGetAchievementQuery } from '@/redux/features/api'
import { useEffect } from 'react'
import { DialogueId } from './useChooseDialogue'
import { isHomePage, isListPage, isShopPage } from '@/utils/path'

const config = {
    defaultIsActive: false,
}

const TUTORIAL_HOME_DIALOGUE: Dialogues = {
    '0': {
        text: "Welcome to the homepage! I'm Plandy.",
        next: '1',
    },
    '1': {
        text: 'Currently, your Planda is a simple, bare necessities planner, consisting of a calendar and todo-list.',
        next: '2',
    },
    '2': {
        text: 'Planda grows with you: The more you use Planda, the more features and productivity techniques you will unlock.',
        next: '3',
    },
    '3': {
        text: `Let's start by adding a task to your todo list. Click on "Tasks" to navigate to your todo list.`,
        next: 'end',
        tutorialArrow: 'list',
        requiresAction: true,
    },
    '': {
        text: 'How on earth did you get here?',
    },
    end: {
        text: "If you are seeing this, you've found a bug. Please let me know how you got here. Thanks!",
    },
}
const dialogues: Dialogues = {
    '0': {
        text: "Welcome to the homepage! I'm Plandy.",
        next: '1',
    },
    '1': {
        text: 'Currently, your Planda is a simple, bare necessities planner, consisting of a calendar and todo-list.',
        next: '2',
    },
    '2': {
        text: 'Planda grows with you: The more you use Planda, the more features and productivity techniques you will unlock.',
        next: '3',
    },
    '3': {
        text: `Let's start by adding a task to your todo list. Click on "Tasks" to navigate to your todo list.`,
        nextOnPage: isListPage,
        tutorialArrow: 'list',
        requiresAction: true,
        next: 'in-list',
    },
    'in-list': {
        text: `Click on "New Task" to add a task to your todo list.`,
        nextOnAchievement: 'create-task-with-form',
        next: 'store',
        tutorialArrow: 'add-task',
        requiresAction: true,
    },
    store: {
        text: `Good job! You've completed the first challenge, so collect your rewards in the shop.`,
        nextOnPage: isShopPage,
        next: 'in-store',
        requiresAction: true,
        tutorialArrow: 'store',
    },
    'in-store': {
        text: `The rest of your tutorials will be in here. Click on the question mark.`,
        tutorialArrow: 'openCurrentLesson',
        next: 'goodbye',
    },
    goodbye: {
        text: `Complete the challenges to unlock new features and earn coins! That's it from me for now, happy planning!`,
        next: 'end',
    },
    '': {
        text: 'How on earth did you get here?',
    },
    end: {
        text: "If you are seeing this, you've found a bug. Please let me know how you got here. Thanks!",
    },
}

/**
 */
export const useNewUserTutorial = (setReady: (x: DialogueId, active?: boolean) => void) => {
    const { data: taskCreated, isLoading } = useGetAchievementQuery('create-task-with-form')
    const pathname = usePathname()
    useEffect(() => {
        const triggerActivePanda = () => {
            if (taskCreated?.id || isLoading || !isHomePage(pathname)) {
                return null
            }
            return true
        }
        const res = triggerActivePanda()
        if (typeof res !== 'boolean') return
        setReady('new-user-tutorial', res)
    }, [taskCreated?.id, isLoading, setReady])

    return {
        dialogues,
        config,
    }
}
