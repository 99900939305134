import { defineRecipe } from '@pandacss/dev'

const blobRecipe = defineRecipe({
    className: 'blob',
    description: 'Inline rounded blob',
    base: {
        display: 'inline-flex',
        alignItems: 'center',
        background: '$info4',
        color: '$hiContrast',
        borderRadius: 5,
        fontSize: 'small',
        padding: '1px 5px',
        margin: '2px',
        border: 'none',
        zIndex: 1,
        cursor: 'pointer',
        '&:hover': {
            scale: 1.05,
        },
    },
})

export default blobRecipe
