import { Label } from '@/components/common/Label'
import { DAYS_OF_WEEK } from '@constants/date'
import { TriangleNav } from '@planda/design-system'
import { mod } from '@planda/utils'
import React from 'react'
import { styled } from 'styled-system/jsx'
import { DynamicWeekStartEnum } from 'src/types'

const DayOfWeekCarouselInput = ({ weekStartsOn, edit }: { weekStartsOn: number, edit: (x: number) => void }) => {
    return (
        <Fieldset>
            <Label>Week starts on:</Label>
            <TriangleNav direction='left' height={0.8} fn={() => edit(mod((weekStartsOn - 1), 7))} />
            <Label>{DAYS_OF_WEEK[weekStartsOn].substring(0, 3)}</Label>
            <TriangleNav direction='right' height={0.8} fn={() => edit(mod((weekStartsOn + 1), 7))} />
        </Fieldset>
    )
}

export default DayOfWeekCarouselInput

export const DynamicDayOfWeekCarouselInput = ({ dynamicWeekStart, edit }: { dynamicWeekStart: DynamicWeekStartEnum, edit: (x: DynamicWeekStartEnum) => void }) => {
    const OPTIONS = DynamicWeekStartEnum.options
    const curIndex = OPTIONS.indexOf(dynamicWeekStart)

    return (
        <Fieldset>
            <Label>Dynamic week start:</Label>
            <TriangleNav direction='left' height={0.8} fn={() => edit(OPTIONS[mod((curIndex - 1), OPTIONS.length)])} />
            <Label>{dynamicWeekStart}</Label>
            <TriangleNav direction='right' height={0.8} fn={() => edit(OPTIONS[mod((curIndex + 1), OPTIONS.length)])} />
        </Fieldset>
    )
}

const Fieldset = styled('fieldset', {
    base: {
        all: "unset",
        display: "flex",
        gap: 20,
        alignItems: "center"
    }
});