import React, { ReactNode } from 'react'
import { css } from 'styled-system/css'

const Badge = ({ children, count, showZero }: { children: ReactNode; count: number, showZero?: boolean }) => {
    return (
        <div className={css({ position: 'relative' })}>
            {children}
            {(showZero || count !== 0) ? (
                <div
                    className={css({
                        // 'z-auto absolute top-0 right-0 min-w-badge min-h-badge font-sans p-0 text-white font-semibold font-xs rounded-xl bg-purple-500 leading-5.5 whitespace-nowrap text-center translate-x-1/2 -translate-y-1/2 drop-shadow-lg origin-right',
                        background: 'red',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        zIndex: 'auto',
                        padding: 2,
                        width: 8,
                        height: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily:
                            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                        fontWeight: 600,
                        textAlign: 'center',
                        color: '#ffffff',
                        whiteSpace: 'nowrap',
                        fontSize: '0.5rem',
                        backgroundColor: '#8B5CF6',
                        '--transform-translate-x': '50%',
                        transformOrigin: 'right',
                        dropShadow: 'drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1))',
                    })}
                >
                    {count}
                </div>
            ) : null}
        </div>
    )
}

export default Badge
