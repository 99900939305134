import { fetchGet } from 'src/lib/fetch';
import { Dialogues, Key } from './types'
import { Dispatch, SetStateAction } from 'react';

const fetchRandomQuote = async () => {
    const quote = await fetchGet<{ text: string, from: string }>('/api/quote')
    return quote.text + '\n' + quote.from;
}

export const IDLE_DIALOGUE: Dialogues = {
    "0": {
        text: "What can I do for you today?",
        responses: [
            {
                "next": "quote",
                "reply": "Inspire me."
            },
            {
                "next": "",
                "reply": "Go away"
            },
        ]
    },
    "quote": {
        text: fetchRandomQuote,
        responses: [
            {
                "next": "quote2",
                "reply": "Inspire me."
            },
            {
                "next": "",
                "reply": "Go away"
            },
        ]
    },
    "quote2": {
        text: fetchRandomQuote,
        responses: [
            {
                "next": "quote",
                "reply": "Inspire me."
            },
            {
                "next": "",
                "reply": "Go away"
            },
        ]
    },
    // "1": {
    //     text: "I'd love to show you around. Where shall we start our paw-some journey?",
    //     responses: [
    //         {
    //             "next": "list",
    //             "reply": "Courses/todo list (suggested)"
    //         },
    //         {
    //             "next": "month",
    //             "reply": "Calendars"
    //         },
    //         {
    //             "next": "self-explore",
    //             "reply": "I'll explore myself."
    //         },
    //         {
    //             "next": "veteran",
    //             "reply": "I know what I'm doing."
    //         },
    //     ]
    // },
    // "self-explore": {
    //     text: "Sounds good! I'll be standing in this corner if you ever change your mind."
    // },
    // "month": {
    //     text: "Click on one of these to navigate to your monthly calendar."
    // },
    // "list": {
    //     text: "Click on one of these to navigate to your courses."
    // },
    // "veteran": {
    //     text: "Thanks for being a Planda user!"
    // },
    // "": {
    //     text: "How on earth did you get here?"
    // }
};
export const defaultHandleNext = (val: Key, setDialogKey: Dispatch<SetStateAction<string>>) => {
    if (!val) {
        setDialogKey("0");
        return;
    }
    val = val.toString();

    setDialogKey(val);
};
