// searches a dictionary for a match
type Item = string | number | string[] | number[]
type InputItem = Item | Record<string, Item>

type MatchStr<T extends string> = T | RegExp
export type MatchOp<T extends string> =
    | { op: 'AND'; valid: MatchParam<T>[] }
    | { op: 'OR'; valid: MatchParam<T>[] }
    | { op: 'GT'; key: T; value: number }
    | { op: 'GEQ'; key: T; value: number }
type MatchParam<T extends string> = MatchStr<T> | MatchOp<T>
export type MatchSchema<T extends string> = MatchStr<T> | MatchOp<T>

export const recursiveMatchArray = <T extends string>(x: MatchSchema<T>, values: Record<string, InputItem>): boolean => {
    if (typeof x === 'string') return x in values
    if (x instanceof RegExp) return Object.keys(values).some((y) => x.test(y))
    if (x.op === 'GEQ') {
        const inputValue = values[x.key]
        if (typeof inputValue !== 'number') return false
        return inputValue >= x.value
    }
    if (x.op === 'GT') {
        const inputValue = values[x.key]
        if (typeof inputValue !== 'number') return false
        return inputValue > x.value
    }
    if (x.op === 'OR') return x.valid.some((y) => recursiveMatchArray(y, values))
    return x.valid.every((y) => recursiveMatchArray(y, values))
}

const recursiveCheckProgressCount = <T extends string>(x: MatchSchema<T>, values: Record<string, InputItem>): number => {
    // if (typeof x === 'string') return values.includes(x) ? 1 : 0
    // if (x instanceof RegExp) return values.some((y) => x.test(y)) ? 1 : 0
    // if (x.op === 'OR') return x.valid.some((y) => recursiveMatchArray(y, values)) ? 1 : 0
    // return x.valid.reduce((acc, y) => acc + recursiveCheckProgressCount(y, values), 0)
    if (typeof x === 'string') return x in values ? 1 : 0
    if (x instanceof RegExp) return Object.keys(values).some((y) => x.test(y)) ? 1 : 0
    if (x.op === 'GT') {
        const inputValue = values[x.key]
        if (typeof inputValue !== 'number') return 0
        return Math.max(inputValue, x.value + 1)
    }
    if (x.op === 'GEQ') {
        const inputValue = values[x.key]
        if (typeof inputValue !== 'number') return 0
        return Math.max(inputValue, x.value)
    }
    if (x.op === 'OR') return x.valid.some((y) => recursiveMatchArray(y, values)) ? 1 : 0
    return x.valid.reduce((acc, y) => acc + recursiveCheckProgressCount(y, values), 0)
}

const recursiveCheckTotal = <T extends string>(x: MatchSchema<T>): number => {
    if (typeof x === 'string') return 1
    if (x instanceof RegExp) return 1
    if (x.op === 'GT') return x.value + 1
    if (x.op === 'GEQ') return x.value
    if (x.op === 'OR') return 1
    return x.valid.reduce((acc, y) => acc + recursiveCheckTotal(y), 0)
}

export const recursiveCheckProgress = <T extends string>(x: MatchSchema<T>, values: Record<string, InputItem>) => {
    const completed = recursiveCheckProgressCount(x, values)
    const total = recursiveCheckTotal(x)
    return { completed, total }
}
