import { Unimportant } from '@/components/common'
import React from 'react'
import { Text } from './Settings'
import Checkbox from '@/components/common/Checkbox'
import { DEFAULT_QUOTES, QuoteTypeEnum } from 'src/types/mascot'
import { Flex, Spinner } from '@planda/design-system'
import { useGetMascotSettingsQuery, useUpdateMascotSettingsMutation } from '@/redux/features/api'
// import type { UpdateItemParams } from 'dynamodb-helpers'

// Planda is your time management assistant
// Planda is your assistant planner
const QuoteSettings = () => {
    const { data } = useGetMascotSettingsQuery()
    const [update] = useUpdateMascotSettingsMutation()

    const updateSettings = (quoteType: QuoteTypeEnum, checked: boolean) => {
        if (!data) return
        const updateItemParams: any = {}
        if (!checked) updateItemParams.delete = { quoteTypes: [quoteType] }
        else updateItemParams.add = { quoteTypes: [quoteType] }

        update({ updates: updateItemParams })
    }

    const quoteTypeIsChecked = (quoteType: QuoteTypeEnum) => {
        if (!data) return DEFAULT_QUOTES.includes(quoteType)
        return data.quoteTypes?.includes(quoteType)
    }

    return (
        <Flex direction={'column'}>
            <Text>Quote settings:</Text>
            <Flex gap={'3'}>
                {
                    data ? QuoteTypeEnum.options.map((quoteType) => {
                        return (
                            <Checkbox key={quoteType} size={'small'} id={quoteType} checked={quoteTypeIsChecked(quoteType)}
                                onCheckedChange={(checked) => updateSettings(quoteType, checked)} >
                                <Unimportant>{quoteType}</Unimportant>
                            </Checkbox>
                        )
                    }) : <Spinner />
                }
                {/* <Checkbox size={'small'} id='inspirational' checked={quoteTypeIsChecked(QuoteTypeEnum.Enum.inspirational)}
                    onCheckedChange={(checked) => updateSettings(QuoteTypeEnum.Enum.inspirational, checked)} >
                    <Unimportant>Inspirational</Unimportant>
                </Checkbox>
                <Checkbox size={'small'} id='biblical' checked={biblicalChecked}
                    onCheckedChange={(checked) => updateSettings(QuoteTypeEnum.Enum.biblical, checked)} >
                    <Unimportant>Biblical</Unimportant>
                </Checkbox> */}
            </Flex>
        </Flex>
    )
}

export default QuoteSettings