import { useGetUserQuery, usePatchUserMutation } from '@/redux/features/api'
import { User } from '@/types'

// TODO: make type for user
export function useUser() {
    const { data, error, isLoading } = useGetUserQuery()
    const [edit] = usePatchUserMutation()

    return {
        user: data,
        isLoading,
        isError: !!error,
        edit: (user: Partial<User>) => edit({ set: user }),
    }
}
