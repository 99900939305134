import React from 'react'
import { css } from 'styled-system/css'

const Woodpecker = () => {
    return (
        <div
            className={css({
                width: '20px',
                height: '80px',
                background: '#935936',
                position: 'relative',
                _before: {
                    content: '""',
                    position: 'absolute',
                    top: '10px',
                    left: '-22px',
                    width: '25px',
                    height: '60px',
                    background:
                        'radial-gradient(farthest-side,#fff 92%,#0000) 60% 6px/4px 4px,\n    radial-gradient(50% 60%,#53707b 92%,#0000) center/70% 55%,\n    radial-gradient(farthest-side,#53707b 92%,#0000) 50% 3px/14px 14px,\n    radial-gradient(142% 100% at bottom right,#0000 64%,#53707b 65%) bottom/57% 40%,\n    conic-gradient(from -120deg at right,#53707b 36deg,#0000 0)100% 3px/51% 12px,\n    conic-gradient(from 120deg at top left,#0000 ,#ef524a 2deg 40deg,#0000 43deg) top/100% 10px',
                    backgroundRepeat: 'no-repeat',
                    transform: 'rotate(-26deg)',
                    transformOrigin: '100% 80%',
                    animation: 'l16 .25s infinite linear alternate',
                },
                _after: {
                    content: '""',
                    position: 'absolute',
                    width: '6px',
                    height: '12px',
                    left: '-6px',
                    bottom: '15px',
                    borderRadius: '100px 0 0 100px',
                    background: '#53707b',
                },
            })}
        >
            Woodpecker
        </div>
    )
}

export default Woodpecker
