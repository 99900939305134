import { defineKeyframes } from '@pandacss/dev'

export const keyframes = defineKeyframes({
    fadeIn: {
        '0%': { opacity: '0' },
        '100%': { opacity: '1' },
    },
    fadeOut: {
        '0%': { opacity: '1' },
        '100%': { opacity: '0' },
    },
    pulse: {
        '0%': { transform: 'scale(1)', boxShadow: '0 0 0 0 colors.$outline' },
        '70%': { transform: 'scale(1.02)', boxShadow: '0 0 0 10px transparent' },
        '100%': { transform: 'scale(1)', boxShadow: '0 0 0 0 transparent' },
    },
    slideUpAndFade: {
        '0%': { opacity: 0, transform: 'translateY(2px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    slideRightAndFade: {
        '0%': { opacity: 0, transform: 'translateX(-2px)' },
        '100%': { opacity: 1, transform: 'translateX(0)' },
    },
    slideDownAndFade: {
        '0%': { opacity: 0, transform: 'translateY(-2px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    slideLeftAndFade: {
        '0%': { opacity: 0, transform: 'translateX(2px)' },
        '100%': { opacity: 1, transform: 'translateX(0)' },
    },
    spinnerSpin: {
        '0%': { top: '8px', height: '64px' },
        '50%, 100%': { top: '24px', height: '32px' },
    },
    toastSlideIn: {
        from: { transform: `translateX(calc(100% + spacing.viewportToastPadding))` },
        to: { transform: 'translateX(0)' },
    },
    toastSwipeOut: {
        from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
        to: { transform: `translateX(calc(100% + spacing.viewportToastPadding))` },
    },
    bounce: {
        '0%, 100%': {
            transform: 'translateY(-25%)',
            animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
        },
        '50%': {
            transform: 'none',
            animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
        },
    },
    bounce2: {
        '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0)' },
        '40%': { transform: 'translateY(-30px)' },
        '60%': { transform: 'translateY(-15px)' },
    },
    footSteps: { '100%': { clipPath: 'inset(0 -30px 0 0)' } },
    l16: { '100%': { transform: 'rotate(0)' } },
    arrow: {
        '0%': {
            left: 0,
        },
        '50%': {
            left: 10,
        },
        '100%': {
            left: 0,
        },
    },
})

export const animations = {
    bounce: { value: 'bounce 1s infinite' },
    footSteps: { value: 'footSteps 2s infinite steps(7)' },
}
