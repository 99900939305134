import { mod } from './math'
import { themeNames } from '../../panda.config'
import { ThemeName } from 'styled-system/themes'
import { getCookie, setCookie } from 'cookies-next'
import { DEFAULT_THEME } from '@/constants/style'

export default function getNextTheme(inc = 1) {
    const theme = getStoredTheme()

    const index = themeNames.indexOf(theme as ThemeName) + inc

    return themeNames[mod(index, themeNames.length)]
}

export const isValidTheme = (name: string | boolean | null | undefined): name is ThemeName => {
    return !!name && themeNames.includes(name as ThemeName)
}

export const getStoredTheme = () => {
    const theme = getCookie('theme')
    if (!isValidTheme(theme)) {
        setCookie('theme', DEFAULT_THEME)
        return DEFAULT_THEME
    }
    return theme
}
