import { stitchesToPandaTokens } from '@planda/design-system'
import { createCategoryVariables } from './utils'

const cobaltCustomVariables = {
    eventcardoutline: 'white',
    // '$blue5',
    eventcardbg: '{colors.$gray9}',
    eventcardtxt: '{colors.$hiContrast}',
    listbg: '{colors.$gray9}',
    monthbg: '{colors.$primary3}',
    // listbg: '$secondary5',
    // listbg: 'linear-gradient(to top left, $gray10, $secondary6)',
    // listbg: 'linear-gradient(to top, $secondary3, $gray10)',
    // listbg: 'linear-gradient(to bottom, $secondary5, $gray10)',
    daygridBg: '{colors.$secondary2}',
    daygridToday: '{colors.$secondary3}',
    calviewbg: '{colors.$secondary3}',
    calviewborder: '{colors.$hiContrast}',
    calviewcolor: '{colors.$hiContrast}',
    weekboxesbg: '{colors.$secondary2}',
    eventbg: '{colors.$secondary7}',
    eventtxt: '{colors.$hiContrast}',
    timegridbg: '{colors.$secondary2}',
    timegridCellHover: '{colors.$secondary4}',
    // sectionHeaderbg: '$gray10',
    // sectionHeadertxt: '$text',
    // sectionHeaderborder: '$gray7',
    ...createCategoryVariables(['#2e4c11', '#6c5a49', '{colors.$gray7}', '#47696e', '#4b7001', '#666666', '#55848e', '{colors.$indigo5}']),
}

const cave = {
    semanticTokens: {
        colors: stitchesToPandaTokens(cobaltCustomVariables),
    },
}

export default cave
