import { useGetMailQuery, useSetMessageAsReadMutation } from '@/redux/features/api'

export const useMail = () => {
    const { data } = useGetMailQuery()
    const [setMessageAsRead] = useSetMessageAsReadMutation()

    return {
        messages: data?.messages || [],
        setMessageAsRead,
    }
}
