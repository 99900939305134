import { useCallback, useState } from 'react'
import { useRuleOfThree } from './useRuleOfThree'
import { useNewUserTutorial } from './useNewUserTutorial'
import { usePathname } from 'next/navigation'
import { isHomePage } from '@/utils/path'
import { IDLE_DIALOGUE } from '../idle'

export type DialogueId = 'new-user-tutorial' | 'rule-of-three'

export const useChooseDialogue = () => {
    const pathname = usePathname()
    const [isActive, setIsActive] = useState(false)
    const [readyQueue, setReadyQueue] = useState<DialogueId[]>([])

    const setReady = useCallback(
        (key: DialogueId, isActive = true) => {
            setReadyQueue((p) => [...p, key])
            if (isActive) setIsActive(true)
        },
        [setReadyQueue]
    )

    const onEnd = useCallback(() => {
        setReadyQueue((p) => p.slice(1))
    }, [])

    const { dialogues: useNewUserTutorialDialogues } = useNewUserTutorial(setReady)
    const { dialogues: ruleOfThreeDialogues } = useRuleOfThree(setReady)

    const keyToDialogue = {
        'new-user-tutorial': useNewUserTutorialDialogues,
        'rule-of-three': ruleOfThreeDialogues,
    }

    const defaultDialogue = isHomePage(pathname) ? IDLE_DIALOGUE : null

    return {
        dialogues: readyQueue.length > 0 ? keyToDialogue[readyQueue[0]] : defaultDialogue,
        onEnd,
        isActive,
        setIsActive,
    }
}
