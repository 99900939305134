import React from 'react'
import TalkingPandaDialogue from '../components/TalkingPandaDialogue'
import { useChooseDialogue } from './useChooseDialogue'

const GeneralPanda = () => {
    // use reduce slice to handle
    const { dialogues, onEnd, isActive, setIsActive } = useChooseDialogue()

    if (!dialogues) return null

    return <TalkingPandaDialogue dialogues={dialogues} onEnd={onEnd} isActive={isActive} setIsActive={setIsActive} />
}

export default GeneralPanda
