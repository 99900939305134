import { signOut } from 'next-auth/react'
import { styled } from 'styled-system/jsx'
import { css } from 'styled-system/css'
import Popover, { PopoverContentProps } from '@/components/common/Popover'
import PandaHead from '../../PandaHead'
import { TrashIcon } from '@radix-ui/react-icons'
import { fetchApplySchool, fetchDeleteUser } from 'src/lib/fetch'
import { Button, Heading, Separator, Switch } from '@planda/design-system'
import YesNoRadio from '@/components/common/YesNoRadio'
import { Root as Label } from '@radix-ui/react-label'
import { SchoolEnum } from 'src/types'
import { useUser } from '@/hooks/useUser'
import { useAppSelector } from '@/redux/hooks'
import { uniq } from 'lodash'
import Link from 'next/link'

// TODO: delete account button + pls clean this up, kinda ugly fast copied everything in
const Profile = ({ children, ...props }: { children?: JSX.Element } & PopoverContentProps) => {
    const { user, edit } = useUser()
    // allow hide animations
    const signOutUser = () => signOut({ callbackUrl: '/auth/signin' })
    const isStudent = useAppSelector((state) => state.featureAccess.plugins.student)
    const isMobileAppPaired = useAppSelector((state) => state.featureAccess.plugins.mobileApp)
    const betaSettingsIsEnabled = useAppSelector((state) => state.featureAccess.featureAccessMap['beta-settings'])

    const ubcId = SchoolEnum.Enum['University of British Columbia']
    const isUBC = user?.school === ubcId
    return (
        <Popover trigger={children || <PandaHead />} {...props}>
            <Flex>
                {/* <Text bold className={css({ marginBottom: 10 })}>
                    Profile
                </Text> */}
                <Heading className={css({ marginBottom: 10, textAlign: 'center', textDecoration: 'underline' })}>Profile</Heading>
                <Fieldset>
                    <Button onClick={signOutUser}>Logout</Button>
                </Fieldset>
                <Fieldset column>
                    <span>Mobile App Connected: {isMobileAppPaired ? 'Yes' : 'No'}</span>
                    {!isMobileAppPaired ? <Link href="/mobile">Connect mobile</Link> : null}
                </Fieldset>
                <Fieldset>
                    Student Mode:{' '}
                    <Switch
                        checked={isStudent}
                        onCheckedChange={(checked) => {
                            edit({
                                plugins: checked ? uniq([...(user?.plugins || []), 'student']) : (user?.plugins || []).filter((x) => x !== 'student'),
                            })
                        }}
                    />
                </Fieldset>
                {betaSettingsIsEnabled && (
                    <Fieldset>
                        Beta:{' '}
                        <Switch
                            checked={isStudent}
                            onCheckedChange={(checked) => {
                                edit({
                                    plugins: checked ? uniq([...(user?.plugins || []), 'beta']) : (user?.plugins || []).filter((x) => x !== 'beta'),
                                })
                            }}
                        />
                    </Fieldset>
                )}
                {isStudent && (
                    <Fieldset>
                        <Flex>
                            <Label>Are you a UBC student?</Label>
                            <YesNoRadio
                                handleChange={(answer) => {
                                    if (answer === 'yes') {
                                        edit({ school: ubcId })
                                    } else {
                                        edit({ school: undefined })
                                    }
                                }}
                                value={isUBC ? 'yes' : 'no'}
                            />
                            {isUBC && <Button onClick={() => fetchApplySchool()}>Load UBC events</Button>}
                        </Flex>
                    </Fieldset>
                )}
                <Separator className={css({ width: '100%' })} />
                <Fieldset>
                    <Button
                        onClick={() => {
                            fetchDeleteUser() && signOutUser()
                        }}
                    >
                        Delete account <TrashIcon />{' '}
                    </Button>
                </Fieldset>
            </Flex>
        </Popover>
    )
}

export default Profile

const Flex = styled('div', {
    base: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
})

export const Fieldset = styled('fieldset', {
    variants: {
        column: {
            true: {
                flexDirection: 'column',
                gap: 5,
            },
        },
    },
    base: {
        all: 'unset',
        display: 'flex',
        gap: 20,
        alignItems: 'center',
    },
})

export const Text = styled('div', {
    variants: {
        faded: {
            true: {
                color: '$gray10',
            },
        },
        bold: {
            true: {
                fontWeight: 500,
            },
        },
    },
    base: {
        margin: 0,
        color: '$text',
        fontSize: '$2',
        lineHeight: '19px',
    },
})
