
import * as LabelPrimitive from '@radix-ui/react-label';
import { styled } from 'styled-system/jsx'

export const Label = styled(LabelPrimitive.Root, {
    variants: {
        strikethrough: {
            true: {
                textDecoration: "line-through"
            },
            false: {}
        },
        size: {
            small: {
                fontSize: "$1"
            }
        },
        showRequiredAsterisk: {
            true: {
                "&::after": {
                    padding: 3,
                    fontSize: "0.9em",
                    color: "$important",
                    content: "*",
                    opacity: 0.9
                }
            }
        }
    },
    base: {
        fontSize: "inherit"
    }
});