import React, { ComponentProps, useCallback, useState } from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider';
import styles from './slider.module.css'
import { debounce } from 'lodash';

type SliderProps = ComponentProps<typeof SliderPrimitive.Root>

const Slider = ({ onDebouncedValueChange, value, ...props }: Omit<SliderProps, 'value' | 'onValueChange'> & { value?: number | number[], onDebouncedValueChange: (value: number[]) => void }) => {
    const [progress, setProgress] = useState(typeof value === 'number' ? [value] : value ?? [0])
    const debounceEdit = useCallback(debounce((value: number[]) => onDebouncedValueChange?.(value), 250), [])

    return (
        <SliderPrimitive.Root value={progress} className={styles.SliderRoot} onValueChange={(value) => {
            debounceEdit(value)
            setProgress(value)
        }} {...props}>
            <SliderPrimitive.Track className={styles.SliderTrack}>
                <SliderPrimitive.Range className={styles.SliderRange} />
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb className={styles.SliderThumb} aria-label="Volume" />
        </SliderPrimitive.Root>
    )
}

export default Slider