export const isHomePage = (pathname: string) => {
    return pathname.includes('/home')
}

export const isListPage = (pathname: string) => {
    return pathname.includes('/list')
}

export const isShopPage = (pathname: string) => {
    return pathname.includes('/store')
}