import { ALL_LESSONS, LessonId } from '@/features/game/levels/config/lessons/lessons'
import { useGetAchievementsQuery, useGetLessonDataQuery } from '@/redux/features/api'
import { lessonChallengeRewardInfo } from '@/utils/lesson'
import { useMemo } from 'react'

const useClaimableRewardsCount = (lessonId: LessonId) => {
    const config = ALL_LESSONS[lessonId]
    const { data, isLoading: isLoadingLessonData } = useGetLessonDataQuery(lessonId)
    const { data: achievements, isLoading: isLoadingAchievements } = useGetAchievementsQuery()

    const claimableRewardsCount = useMemo(() => {
        if (isLoadingLessonData) return 0
        return config.challenges.reduce((acc, cur) => {
            return acc + (lessonChallengeRewardInfo(cur.id, lessonId, data, achievements).canClaimReward ? 1 : 0)
        }, 0)
    }, [data, achievements, lessonId, isLoadingLessonData])

    return {
        claimableRewardsCount,
        isLoading: isLoadingLessonData || isLoadingAchievements,
    }
}

export default useClaimableRewardsCount
