import Image from 'next/image'
import { forwardRef } from 'react'
import { css, cx } from 'styled-system/css';
import { SystemStyleObject } from 'styled-system/types';

const imageWrapper = css.raw({
    boxSizing: "border-box",
    position: "relative",
    content: "image-wrapper",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
    overflow: "hidden",
    userSelect: "none",
    width: 60,
    height: 60
})


const PandaHead = forwardRef<any, any>(({ children, cssRaw, ...props }, forwardedRef) => {
    return (
        <div className={css(imageWrapper, cssRaw as SystemStyleObject | undefined)} ref={forwardedRef} {...props} >
            <Image
                src={'/panda_head_outline.png'}
                fill
                className={css({ objectFit: 'contain' })}
                title="Click to view Profile"
                alt="Panda Mascot"
            // objectPosition={'50% 50%'}
            />
        </div>
    )
})
PandaHead.displayName = 'PandaHead'

export default PandaHead