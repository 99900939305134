import { stitchesToPandaTokens } from '@planda/design-system'

const greyTheme = {
    tasktxt: '{colors.$loContrast}',
    taskbg: '{colors.$primary9}',

    eventbg: '{colors.$secondary3}',
    eventtxt: '{colors.$hiContrast}',

    // listbg: 'linear-gradient(to top left, white, $black)',
    listbg: '{colors.$primary3}',

    timegridbg: '{colors.$primary2}',
    timegridCellHover: '{colors.$primary4}',

    eventcardoutline: '{colors.$slate9}',
    eventcardbg: '{colors.$slate7}',
    eventcardtxt: '{colors.$hiContrast}',

    categorybg1: '#eeeeee',
    categorytxt1: '{colors.$hiContrast}',

    categorybg2: '#dddddd',
    categorytxt2: '{colors.$hiContrast}',

    categorybg3: '#cccccc',
    categorytxt3: '{colors.$hiContrast}',

    categorybg4: '#bbbbbb',
    categorytxt4: '{colors.$hiContrast}',

    categorybg5: '#a3a3a3',
    categorytxt5: '{colors.$hiContrast}',

    categorybg6: '#888888',
    categorytxt6: '{colors.$hiContrast}',

    categorybg7: '#999999',
    categorytxt7: '{colors.$hiContrast}',

    categorybg8: '#666666',
    categorytxt8: '{colors.$hiContrast}',

    // daygridBg: '{colors.$secondary4', // '#EADDCA}', // dark , !!
    // daygridToday: '{colors.$secondary6', // '$lbrownv}', // dark

    // calviewbg: '{colors.$lightgreen3v}',
    // calviewborder: '{colors.$primary9}',
    // calviewcolor: '{colors.$primary11}',
}

const grey = {
    semanticTokens: {
        colors: stitchesToPandaTokens(greyTheme),
    },
}
export default grey
