'use client'
import { setOfflineMode } from '@/redux/features/view-mode/viewModeSlice'
import { useAppDispatch } from '@/redux/hooks'
import { useCallback, useEffect } from 'react'

const inBrowser = typeof navigator !== 'undefined'

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/

type Polling =
    | {
          url: string
          interval: number
          timeout: number
      }
    | boolean

export const pingIsOnline = ({ url, timeout }: { url: string; timeout: number }) => {
    return new Promise<boolean>((resolve) => {
        const isOnline = () => resolve(true)
        const isOffline = () => resolve(false)

        const xhr = new XMLHttpRequest()

        xhr.onerror = isOffline
        xhr.ontimeout = isOffline
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                if (xhr.status) {
                    isOnline()
                } else {
                    isOffline()
                }
            }
        }

        xhr.open('GET', url)
        xhr.timeout = timeout
        xhr.send()
    })
}

// const defaultProps = {
//     polling: true,
//     wrapperType: 'span',
// }

const defaultPollingConfig = {
    enabled: inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
    url: 'https://httpbin.org/get',
    timeout: 5000,
    interval: 5000,
}

function getPollingConfig(polling: Polling) {
    switch (polling) {
        case true:
            return defaultPollingConfig
        case false:
            return { enabled: false } as const
        default:
            return Object.assign({}, defaultPollingConfig, polling)
    }
}

const useDetectOffline = (polling: Polling = true) => {
    const dispatch = useAppDispatch()
    const setOffline = useCallback(
        (offline: boolean) => {
            console.log('setting offline', offline)
            dispatch(setOfflineMode(offline))
        },
        [dispatch]
    )

    useEffect(() => {
        setOffline(inBrowser && typeof navigator.onLine === 'boolean' ? !navigator.onLine : false)

        const goOnline = () => setOffline(false)
        const goOffline = () => setOffline(true)
        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)

        return () => {
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [setOffline])

    useEffect(() => {
        const pollingConfig = getPollingConfig(polling)
        if (!pollingConfig.enabled) return
        const { interval } = pollingConfig
        const pollingId = setInterval(() => {
            // @ts-ignore
            const { url, timeout } = getPollingConfig(polling)
            pingIsOnline({ url, timeout }).then((online) => {
                setOffline(!online)
            })
        }, interval)
        return () => {
            if (pollingId) {
                clearInterval(pollingId)
            }
        }
    }, [polling, setOffline])
}

export default useDetectOffline
