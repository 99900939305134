import { styled } from 'styled-system/jsx'
import Popover, { PopoverContentProps } from '@/components/common/Popover'
import { css } from 'styled-system/css';
import PandaHead from '../../PandaHead'
import { Link2Icon } from '@radix-ui/react-icons'
import { Flex } from '@planda/design-system'
import Link from 'next/link'
import { BsFacebook, BsLinkedin, BsReddit } from 'react-icons/bs'

// TODO: delete account button + pls clean this up, kinda ugly fast copied everything in

const linkHolderStyles = {
    position: 'relative',
    display: 'flex',
    gap: 10,
    boxShadow: '$boxShadow',
    borderRadius: '$4',
    alignItems: 'center',
    padding: '$2',
    color: '$text',
    fontSize: '$2',
}
const CopyButton = styled('button', {
    base: {
        background: "none",
        border: "none",
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
        ...linkHolderStyles,
    }
})
const LinkHolder = styled(Link, { base: linkHolderStyles })
const Share = ({ children, ...props }: { children?: JSX.Element } & PopoverContentProps) => {
    return (
        <Popover trigger={children ||
            <PandaHead />
        } {...props} >
            <Flex direction={'column'} gap={'3'}>
                <Text bold className={css({ marginBottom: 10 })}>
                    Share
                </Text>
                <LinkHolder target='_blank' href={'https://planda.ca/socials/share'}>
                    <Link2Icon />
                    Share your calendar
                </LinkHolder>
                <CopyButton onClick={() => { navigator.clipboard.writeText('https://planda.ca') }}>
                    <Link2Icon />
                    Copy link
                </CopyButton>
                <LinkHolder target='_blank' href={'https://www.reddit.com/submit?url=https://planda.ca'}>
                    <BsReddit />
                    Share on Reddit
                </LinkHolder>
                <LinkHolder target='_blank' href={'https://www.linkedin.com/sharing/share-offsite/?url=https://planda.ca'}>
                    <BsLinkedin />
                    Share on LinkedIn
                </LinkHolder>
                <LinkHolder target='_blank' href={'http://www.facebook.com/share.php?u=https://planda.ca'}>
                    <BsFacebook />
                    Share on Facebook
                </LinkHolder>
            </Flex>
        </Popover>
    )
}

export default Share

export const Text = styled('div', {
    variants: {
        faded: {
            true: {
                color: "$gray10"
            }
        },
        bold: {
            true: {
                fontWeight: 500
            }
        }
    },
    base: {
        margin: 0,
        color: "$text",
        fontSize: "$2",
        lineHeight: "19px"
    }
});