import useActive from './useActive'
import { Event, Task, isDBTaskId } from 'src/types'
import { useGetUserQuery } from '@/redux/features/api'
import { useMemo } from 'react'
import { groupBy } from 'lodash'
import { addWeeks, format, isToday, startOfWeek } from 'date-fns'
import { DayOfWeek } from '@/features/calendars/types'
import { MS_PER_MINUTE } from '@/constants/date'
import { formatDurationSimplified } from '@planda/utils'

export interface TaskEventInfo {
    minutesCompleted: number
    scheduledUpcomingMinutes: number
    upcomingEvents: Event[]
    completedReadable: string
    upcomingReadable: string
}

export default function useTasks(path?: string) {
    const props = useActive('task', path)
    const items = props.items as Task[] | undefined
    const { data: user } = useGetUserQuery()

    const taskEventsDict = useMemo(() => {
        const events = props.categoryRes?.items.event || []
        const dict = groupBy(
            events.filter((x) => x.parentId && isDBTaskId(x.parentId)),
            (x) => x.parentId
        )
        const splitDict: Record<string, TaskEventInfo> = {}
        const dateNow = Date.now()
        Object.entries(dict).forEach(([key, value]) => {
            const formatMinutes = (minutes: number) => {
                return formatDurationSimplified({ minutes: Math.round(minutes) })
            }
            const minutesCompleted =
                value.reduce((acc, x) => {
                    return acc + Math.max(Math.min(dateNow, x.dateEnd) - x.dateStart, 0)
                }, 0) / MS_PER_MINUTE
            const scheduledUpcomingMinutes =
                value.reduce((acc, x) => {
                    return acc + Math.max(x.dateEnd - Math.max(dateNow, x.dateStart), 0)
                }, 0) / MS_PER_MINUTE
            splitDict[key] = {
                minutesCompleted,
                scheduledUpcomingMinutes,
                completedReadable: formatMinutes(minutesCompleted),
                upcomingReadable: formatMinutes(scheduledUpcomingMinutes),
                upcomingEvents: value.filter((x) => x.dateEnd > Date.now()),
            }
        })
        return splitDict
    }, [props.categoryRes])

    const nextWorkingOn = (id: string) => {
        const nextEvent = taskEventsDict[id]?.upcomingEvents[0]
        if (!nextEvent) return
        if (isToday(nextEvent.dateStart))
            return {
                dateString: 'Today',
                dateCategory: 'today' as const,
            }
        const nextWeekStart = addWeeks(startOfWeek(Date.now(), { weekStartsOn: user?.weekStartsOn as DayOfWeek }), 1).getTime()

        if (nextEvent.dateStart < nextWeekStart)
            return {
                dateString: format(nextEvent.dateStart, 'EEE'),
                dateCategory: 'week' as const,
            }
        return {
            dateString: format(nextEvent.dateStart, 'MMM d'),
            dateCategory: 'future' as const,
        }
    }

    return {
        ...props,
        items,
        nextWorkingOn,
        taskEventsDict,
        overdueTasks: items?.filter((x) => !x.completed && x.dateStart && x.dateStart < Date.now()) || [],
    }
}
