import React from 'react'
import TalkingPanda from './TalkingPanda'
import usePandaDialogue from '../choose/usePandaDialogue'
import { Dialogues } from '../types'

const TalkingPandaDialogue = ({
    dialogues,
    onEnd,
    isActive,
    setIsActive,
}: {
    dialogues: Dialogues
    onEnd?: () => void
    isActive: boolean
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { dialogue, handleNext } = usePandaDialogue(dialogues, onEnd)
    return (
        <TalkingPanda
            isActive={isActive}
            setIsActive={setIsActive}
            dialogue={dialogue}
            text={dialogue.text}
            onNext={handleNext}
        />
    )
}

export default TalkingPandaDialogue
