import { ALL_LESSONS, LessonId } from '@/features/game/levels/config/lessons/lessons'
import { RequirementId } from '@/features/game/levels/config/types'
import { AchievementRes } from '@/types/game'
import { LessonData } from '@/types/lesson'
import { recursiveCheckProgress, recursiveMatchArray } from '@/utils/match'

export const isLessonRequirementCompleted = (idOrIndex: RequirementId | number, lessonId: LessonId, achievements: AchievementRes | undefined) => {
    const challenges = ALL_LESSONS[lessonId].challenges
    const requirement = typeof idOrIndex === 'number' ? challenges[idOrIndex] : challenges.find((r) => r.id === idOrIndex)
    if (!requirement) {
        console.error(`Requirement ${idOrIndex} not found`, challenges)
        throw new Error(`Requirement ${idOrIndex} not found`)
    }
    if (!achievements) return false
    const isComplete = recursiveMatchArray(requirement.achievementsRequired, achievements)
    if (isComplete) return true
    return recursiveCheckProgress(requirement.achievementsRequired, achievements)
}

export const lessonChallengeRewardInfo = (
    id: RequirementId,
    lessonId: LessonId,
    data: LessonData | undefined | null,
    achievements: AchievementRes | undefined
) => {
    const completionRes = isLessonRequirementCompleted(id, lessonId, achievements)
    const isComplete = typeof completionRes === 'boolean' && completionRes

    const rewardIsClaimed = !!data?.challenges[id]?.claimedAt
    const canClaimReward = isComplete && !rewardIsClaimed
    return {
        isComplete,
        rewardIsClaimed,
        canClaimReward,
        completionRes,
    }
}
